import { post } from "./api";
export function getPositionFamilyList(params) {
    return post("/v1/profession/evaluation/family", { request: params })
}
export function getPositionLevelListByFamily(params) {
    return post("/v1/profession/code/level/list", { request: params })
}
export function getPositionList(params) {
    return post("/v1/profession/evaluation/job/list", { request: params })
}
export function getPositions(params) {
    return post('/v1/profession/office/list', { request: params });
}
export function getLevelList(params) {
    return post('/v1/profession/orgLevel/list', params);
}