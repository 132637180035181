<template>
	<app-layout v-loading="pageLoaing" :need-header="false">
		<div class="rating-page__wap">
			<article class="rating-page-left">
				<div class="section">
					<h1 class="title-icon"><span>职级评估</span></h1>
					<section>
						<el-form label-width="80px">
							<el-form-item label="职族">
								<el-select
									size="small"
									class="select-item"
									:value="officeInfo.familyName"
									@focus="showPicker(JobClassSheet)"
									popper-class="hidden-popper"
								>
								</el-select>
							</el-form-item>
							<el-form-item label="职级代码">
								<el-select
									size="small"
									class="select-item"
									:disabled="!officeInfo.familyId"
									:value="officeInfo.officeCode"
									@focus="showPicker(PositionLevelSheet)"
									popper-class="hidden-popper"
								>
								</el-select>
							</el-form-item>
							<el-form-item label="职级">
								<el-select
									size="small"
									:value="officeInfo.level"
									class="select-item disable-input__suffix"
									:disabled="!officeInfo.familyId"
									popper-class="hidden-popper"
								>
								</el-select>
							</el-form-item>
							<el-form-item label="岗位">
								<el-select
									size="small"
									:value="officeInfo.jobName"
									@focus="showPicker(PositionSheet)"
									popper-class="hidden-popper"
									:disabled="!officeInfo.officeCode"
									class="select-item"
								>
								</el-select>
							</el-form-item>
							<el-form-item label="职责描述">
								<p style="font-size: 12px; line-height: 1.5em; letter-spacing: 2px; color: #606266">
									{{ officeInfo.dutyIllustrate }}
								</p>
							</el-form-item>
						</el-form>
						<div v-if="$route.params.type === 'again' && otherEvaluationList && otherEvaluationList.length > 0">
							<h4 style="margin: 12px 0">其他人的评价</h4>
							<table class="evaluation-table">
								<template v-for="(row, i) in otherEvaluationList">
									<tr :key="`assessorName_${i}`" class="evaluation-table__header">
										<th colspan="2">
											<h3>
												{{ row.assessorName }}
											</h3>
										</th>
									</tr>
									<tr :key="`score_${i}`">
										<td>得分</td>
										<td>
											{{ row.score }}
										</td>
									</tr>
									<tr :key="`jobEtc_${i}`">
										<td>职等</td>
										<td>
											{{ row.jobEtc }}
										</td>
									</tr>
									<tr :key="`officeCode_${i}`">
										<td>职级代码</td>
										<td>{{ row.officeCode }}</td>
									</tr>
									<tr :key="`jobName_${i}`">
										<td>岗位</td>
										<td>{{ row.jobName }}</td>
									</tr>
									<tr :key="`fileList_ ${i}`">
										<td>附件</td>
										<td>
											<a
												:key="i"
												v-for="(file, i) in row.fileList || []"
												:href="file.url || file.httpUrl"
												class="link-text fj-icon"
											>
												{{ file.name || file.fileName }}
											</a>
										</td>
									</tr>
								</template>
							</table>
						</div>
					</section>
				</div>
				<div class="section">
					<h1 class="title-icon"><span>职等评估</span></h1>
					<section class="rating-item" v-for="(item, i) in formData" :key="i">
						<h2 class="title" v-if="item.className">{{ (i + 1) | numToCN }}、{{ item.className }}</h2>
						<div v-for="(problem, ci) in item.indicatorList" :key="ci">
							<h2 class="title" v-if="!item.className">{{ (i + 1) | numToCN }}、{{ problem.indicatorName }}</h2>
							<h3 class="title" v-else>{{ ci + 1 }}、{{ problem.indicatorName }}</h3>
							<el-radio-group class="radio-group" v-model="problem.selectedOptionId">
								<el-radio
									:key="oi"
									@click.native="changeRadio(option, problem)"
									class="radio-item"
									v-for="(option, oi) in problem.optionList"
									:label="option.optionId"
									>{{ option.content }}</el-radio
								>
							</el-radio-group>
							<template v-if="$route.params.type === 'self'">
								<p>举例说明<i v-if="problem.requiredExample" style="color: red; padding: 0 8px">(必填)</i></p>
								<el-input
									class="for-exemple"
									type="textarea"
									resize="none"
									maxlength="250"
									show-word-limit
									v-model="problem.reason"
									:autosize="{ minRows: 4, maxRows: 4 }"
									rows="4"
								></el-input>
								<el-upload
									:file-list="problem.nativeFileList"
									:limit="3"
									multiple
									:on-exceed="handleExceed"
									name="imgFile"
									:before-upload="handleBeforeUpload"
									:on-success="
										(res, file, filelist) => {
											handleUploaded(res, file, filelist, problem);
										}
									"
									:on-remove="
										(file, filelist) => {
											handleRemoveFile(file, filelist, problem);
										}
									"
									:action="FileUploadAction"
								>
									<el-button type="text" class="el-icon-circle-plus">添加附件</el-button>
								</el-upload>
							</template>
							<div v-if="$route.params.type === 'again' && problem.otherEvaluationList && problem.otherEvaluationList.length > 0">
								<h4 style="margin: 12px 0">其他人的评价</h4>
								<table class="evaluation-table">
									<template v-for="(row, i) in problem.otherEvaluationList">
										<tr :key="`assessorName_${i}`" class="evaluation-table__header">
											<th colspan="2">
												<h3>
													{{ row.assessorName }}
												</h3>
											</th>
										</tr>
										<tr :key="`score_${i}`">
											<td>得分</td>
											<td>
												{{ row.score }}
											</td>
										</tr>
										<tr :key="`content_${i}`">
											<td>选择项</td>
											<td>
												{{ row.content }}
											</td>
										</tr>
										<tr :key="`reason_${i}`">
											<td>举例说明</td>
											<td>{{ row.reason }}</td>
										</tr>
										<tr :key="`fileList_ ${i}`">
											<td>附件</td>
											<td>
												<a
													:key="i"
													v-for="(file, i) in row.fileList || []"
													:href="file.url || file.httpUrl"
													class="link-text fj-icon"
												>
													{{ file.name || file.fileName }}
												</a>
											</td>
										</tr>
									</template>
								</table>
							</div>
						</div>
					</section>
				</div>
				<div class="section reply-report" v-if="$route.params.type === 'self'">
					<h1 class="title-icon">自述答辩报告</h1>
					<section class="rating-item">
						<el-form>
							<el-form-item>
								<el-upload
									:file-list="nativeFileList"
									:limit="3"
									:on-exceed="handleExceed"
									multiple
									name="imgFile"
									:before-upload="handleBeforeUpload"
									:on-success="
										(res, file, filelist) => {
											handleUploaded(res, file, filelist, { fileList: accessoryUrl });
										}
									"
									:on-remove="
										(file, filelist) => {
											handleRemoveFile(file, filelist, { fileList: accessoryUrl });
										}
									"
									:action="FileUploadAction"
								>
									<el-button type="primary" size="small">上传文件</el-button>
								</el-upload>
								<div class="el-upload__tip">文件格式为建议上传ppt格式，大小在10MB以内</div>
								<div class="file-pre-download" :key="i" v-for="(item, i) in evaluationTemplates">
									<img src="../../assets/DOC.png" width="35px" height="35px" />
									<div>
										<p class="file-name">{{ item.groupName }}</p>
										<a class="text-high-light" :href="item.dictName">下载</a>
									</div>
								</div>
							</el-form-item>
						</el-form>
					</section>
				</div>
			</article>
		</div>
		<div slot="footer" style="text-align: center">
			<el-button @click="$router.back()">取消</el-button>
			<el-button type="primary" :disabled="uploadQueue.length > 0" @click="save">保存</el-button>
			<el-button @click="submit" :disabled="uploadQueue.length > 0" type="primary">提交</el-button>
		</div>
		<action-sheet v-model="pickerVisible" position="bottom" style="height: 70%; width: 100%">
			<app-layout size="small" v-loading="tableLoading" :need-footer="false">
				<div slot="header">
					<div class="popup-title">
						<el-button size="mini" @click="pickerVisible = false" type="text">取消 </el-button>
						<h3>{{ action }}</h3>
						<el-button size="mini" @click="cofirmSelect(action)" type="text">确认</el-button>
					</div>
					<el-row type="flex" v-if="action === PositionLevelSheet" class="sheet-title">
						<el-col :span="4">职级代码</el-col>
						<el-col :span="4">职等</el-col>
						<el-col :span="16">职责描述</el-col>
					</el-row>
				</div>
				<div style="padding-bottom: 50px">
					<template v-if="action === JobClassSheet">
						<div @click="changeCurrentSelect(JobClassSheet, item)" :key="i" v-for="(item, i) in positionFamilyList">
							<el-row :class="{ 'position-level-item__wap': true, active: item.id === currentFamily.id }" type="flex">
								<el-col>{{ item.name }}</el-col>
							</el-row>
						</div>
					</template>
					<template v-else-if="action === PositionLevelSheet">
						<div @click="changeCurrentSelect(PositionLevelSheet, item)" :key="i" v-for="(item, i) in positionLevelList">
							<el-row
								:class="{
									'shrink-item': true,
									'position-level-item__wap': true,
									active: `${item.officeCode}_${item.level}` === `${currentPositionLevel.officeCode}_${currentPositionLevel.level}`
								}"
								type="flex"
							>
								<el-col :span="4">{{ item.officeCode }}</el-col>
								<el-col :span="4">{{ item.level }}</el-col>
								<el-col :span="16">
									<p :class="{ 'text-ellipsis': item.id !== openUpItem }" style="text-align: left">{{ item.dutyIllustrate }}</p>
									<i
										:class="item.id !== openUpItem ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
										@click.stop="openShrink(item.id)"
										style="position: absolute; bottom: 1px"
									></i>
								</el-col>
							</el-row>
						</div>
					</template>
					<template v-else-if="action === PositionSheet">
						<div @click="changeCurrentSelect(PositionSheet, item)" :key="i" v-for="(item, i) in positionList">
							<el-row
								:class="{ 'position-level-item__wap': true, active: item.levelAndCodeId === currentPosition.levelAndCodeId }"
								type="flex"
							>
								<el-col>{{ item.jobName }}</el-col>
							</el-row>
						</div>
					</template>
				</div>
			</app-layout>
		</action-sheet>
	</app-layout>
</template>
<script>
import { saveEvaluation, submitEvaluation, loginAndStartJudge, getJugeAgainProblems } from '../../api/wap';
import { Notify, Dialog, Popup } from 'vant';
import { makePositionLevelTxt } from '../../utils/index';
import { FileUploadAction, RequiredError } from '../../utils/const';
import { getEvaluationTemplates } from '../../utils/dict';
import * as Position from '../../api/position';
import 'vant/lib/popup/style';
import 'vant/lib/dialog/style';
import 'vant/lib/notify/style';
const PositionSheet = '选择岗位';
const JobClassSheet = '选择职族';
const PositionLevelSheet = '选择职等';

export default {
	components: {
		'action-sheet': Popup
	},
	async mounted() {
		this.pageLoaing = true;
		let params = {};

		try {
			const pageType = this.$route.params.type;

			if (pageType === 'self' && sessionStorage.getItem('selfJudgeWapLoginInfo')) {
				params = JSON.parse(sessionStorage.getItem('selfJudgeWapLoginInfo'));
				this.evaluationTemplates = getEvaluationTemplates();
			} else if (pageType === 'again' && sessionStorage.getItem('againJudgeWapLoginInfo')) {
				params = JSON.parse(sessionStorage.getItem('againJudgeWapLoginInfo'));
				this.params.id = this.$route.query.id;
			} else {
				let query = sessionStorage.getItem('wapLoginQuery');

				if (query) {
					query = JSON.parse(query);
				} else {
					query = {};
					Notify({
						type: 'danger',
						message: '参数错误!'
					});
				}
				this.$router.replace({
					path: `/wap/login/${pageType}`,
					query
				});
			}
			this.params.userName = params.userName;
			this.params.jobNumber = params.jobNumber;
			this.customerComId = sessionStorage.getItem('customerComId');
			const data = await this.getEvaluationForm();

			if (data) {
				this.setEvaluationForm(data);
				this.$nextTick(() => {
					this.levelAndCodeId = this.levelAndCodeId;
				});
			}
		} finally {
			this.pageLoaing = false;
		}
	},
	computed: {
		title() {
			return `${this.$route.query.employeeName}-人岗评估`;
		}
	},
	data() {
		return {
			FileUploadAction,
			PositionSheet,
			JobClassSheet,
			PositionLevelSheet,
			ManageEvaluationTemplateFile: '',
			NotManageEvaluationTemplateFile: '',
			openUpItem: '',
			evaluationTemplates: [],
			customerComId: '',
			action: '',
			assessorResultId: '',
			saasKey: '',
			staffId: '',
			staffResultId: '',
			tableLoading: false,
			finished: false,
			submitting: false,
			pickerVisible: false,
			currentSelection: {},
			pageLoaing: false,
			params: {},
			officeInfo: {},
			otherEvaluationList: [],
			accessoryUrl: [],
			nativeFileList: [],
			positionLevelParams: {
				pageNo: 1,
				pageSize: 500
			},
			formData: [],
			uploadQueue: [],
			reason: '',
			currentFamily: {},
			currentPositionLevel: {},
			currentPosition: {},
			positionList: [],
			positionLevelList: [],
			positionFamilyList: [],
			positionLevelDatas: [],
			positionLevelTxt: '',
			levelAndCodeId: null
		};
	},

	methods: {
		makeFileList(json) {
			try {
				const fileList = JSON.parse(json);

				return fileList || [];
			} catch (err) {
				return [];
			}
		},
		clearBefore(type) {
			switch (type) {
				case JobClassSheet:
					this.officeInfo.level = '';
					this.officeInfo.officeCode = '';
					this.officeInfo.dutyIllustrate = '';
					this.positionLevelList = [];
					this.currentPositionLevel = {};
				/* fall throught*/
				case PositionLevelSheet:
					this.levelAndCodeId = null;
					this.officeInfo.jobName = '';
					this.positionList = [];
					this.currentPosition = {};
				/* fall throught*/
				default:
					break;
			}
		},
		openShrink(key) {
			if (this.openUpItem === key) {
				this.openUpItem = '';
			} else {
				this.openUpItem = key;
			}
		},
		cofirmSelect(type) {
			let err = false;

			switch (type) {
				case JobClassSheet:
					if (!this.currentFamily.id) {
						err = true;
						break;
					}
					this.officeInfo.familyId = this.currentFamily.id;
					this.officeInfo.familyName = this.currentFamily.name;
					break;
				case PositionLevelSheet:
					if (!this.currentPositionLevel.officeCode) {
						err = true;
						break;
					}
					this.officeInfo.level = this.currentPositionLevel.level;
					this.officeInfo.officeCode = this.currentPositionLevel.officeCode;
					this.officeInfo.dutyIllustrate = this.currentPositionLevel.dutyIllustrate;
					break;
				case PositionSheet:
					if (!this.currentPosition.levelAndCodeId) {
						err = true;
						break;
					}
					this.levelAndCodeId = this.currentPosition.levelAndCodeId;
					this.officeInfo.jobName = this.currentPosition.jobName;
					break;
				default:
					err = true;
					break;
			}
			if (err === true) {
				Notify({
					type: 'warning',
					message: '请选择'
				});
			} else {
				this.clearBefore(type);
				this.pickerVisible = false;
			}
		},
		changeCurrentSelect(type, item) {
			switch (type) {
				case JobClassSheet:
					this.currentFamily = item;
					break;
				case PositionLevelSheet:
					this.currentPositionLevel = item;
					break;
				case PositionSheet:
					this.currentPosition = item;
					break;
				default:
					break;
			}
		},
		async showPicker(action) {
			this.pickerVisible = true;
			this.action = action;
			this.tableLoading = true;
			try {
				switch (action) {
					case PositionSheet:
						this.positionList = [];
						await this.getPositionList({
							familyId: this.officeInfo.familyId,
							level: this.officeInfo.level,
							officeCode: this.officeInfo.officeCode,
							userName: this.params.userName,
							jobNumber: this.params.jobNumber,
							customerComId: this.customerComId
						});
						break;
					case JobClassSheet:
						this.positionFamilyList = [];
						await this.getPositionFamilyList({
							userName: this.params.userName,
							staffResultId: this.staffResultId,
							jobNumber: this.params.jobNumber,
							customerComId: this.customerComId
						});
						break;
					case PositionLevelSheet:
						this.positionLevelList = [];
						await this.getPositionLevelListByFamily({
							familyId: this.officeInfo.familyId,
							userName: this.params.userName,
							jobNumber: this.params.jobNumber,
							customerComId: this.customerComId
						});
						break;
					default:
						break;
				}
			} finally {
				this.tableLoading = false;
			}
		},
		async getPositionFamilyList(params) {
			params = Object.assign(params);
			return Position.getPositionFamilyList(params).then((res) => {
				if (res._responseStatusCode === 0) {
					this.positionFamilyList = res;
					return true;
				}
				return false;
			});
		},
		async getPositionLevelListByFamily(params) {
			params = Object.assign(params);
			return Position.getPositionLevelListByFamily(params).then((res) => {
				if (res._responseStatusCode === 0) {
					this.positionLevelList = res;
					return true;
				}
				return false;
			});
		},
		async getPositionList(params) {
			params = Object.assign(params);
			return Position.getPositionList(params).then((res) => {
				if (res._responseStatusCode === 0) {
					this.positionList = res;
					return true;
				}
				return false;
			});
		},
		submit() {
			if (!this.levelAndCodeId) {
				Notify({
					message: '请选择职级评估!',
					type: 'danger'
				});
				return;
			}
			Dialog.confirm({
				message: '确认要提交本次评价？',
				title: '提示'
			}).then(() => {
				let evaluationSubmitRequestList = [];

				try {
					evaluationSubmitRequestList = this.evaluationSubmitRequestList();
					if (this.$route.params.type === 'self' && (!this.accessoryUrl || this.accessoryUrl.length === 0)) {
						throw new RequiredError('必须上传自辩答述报告!');
					}
				} catch (err) {
					if (err instanceof RequiredError) {
						Notify({ type: 'danger', message: err.message });
					}
					return;
				}
				this.pageLoaing = true;
				submitEvaluation({
					assessorResultId: this.assessorResultId,
					levelAndCodeId: this.levelAndCodeId,
					userName: this.params.userName,
					customerComId: this.customerComId,
					jobNumber: this.params.jobNumber,
					reason: this.reason,
					accessoryUrl: this.accessoryUrl ? JSON.stringify(this.accessoryUrl) : '[]',
					evaluationSubmitRequestList
				})
					.then((res) => {
						this.pageLoaing = false;
						if (res._responseStatusCode === 0) {
							Notify({ type: 'success', message: '操作成功!' });
							const h = this.$createElement;

							this.$msgbox({
								title: '',
								customClass: 'wap-evaluate-p',
								closeOnClickModal: false,
								message: h('div', { class: 'wap-evaluate' }, [
									h('div', { class: 'wap-evaluate_img' }, [h('p', `${this.params.userName}`)]),
									h('div', { class: 'wap-evaluate_text' }, [
										h('h3', null, '人岗匹配评估结果'),
										h('p', null, [h('em', null, `职位${this.params.jobNumber}`), h('span', null, `${res.score}`)]),
										h('p', null, [h('em', null, '职等'), h('span', null, `${res.jobEtc}`)])
									])
								]),
								showCancelButton: false,
								showConfirmButton: false,
								confirmButtonText: '确定',
								callback: () => {
									this.$router.back();
								}
							});
						}
					})
					.catch(() => {
						this.pageLoaing = false;
					});
			});
		},
		handleUploaded(res, file, filelist, item) {
			this.uploadQueue.shift();
			if (res.code === 0) {
				Notify({ type: 'success', message: '上传成功!' });
				const resFileMeta = {
					name: res.data.fileName,
					size: file.size,
					url: res.data.httpUrl,
					bucketName: res.data.bucketName,
					key: res.data.key
				};

				item.fileList.push(resFileMeta);
			} else {
				Notify({
					type: 'danger',
					message: '上传失败'
				});
				filelist.pop();
			}
		},
		handleExceed(files, filelist) {
			if (files.length > 3 || filelist.length === 3 || files.length + filelist.length > 3) {
				this.$message({
					message: '上传文件数量不能超过三个',
					type: 'warning'
				});
			}
		},
		handleBeforeUpload(file) {
			this.uploadQueue.push(1);
			return file;
		},
		handleRemoveFile(file, filelist, item) {
			if (file.status === 'uploading') {
				this.uploadQueue.pop();
			}
			let fileKey = '';

			if (file.response) {
				fileKey = file.response.data.key;
			} else {
				fileKey = file.key;
			}
			const index = item.fileList.findIndex((v) => v.key === fileKey);

			if (index !== -1) {
				item.fileList.splice(index, 1);
			}
		},
		changeRadio(option, problem) {
			if (option.requiredStatus === 1) {
				this.$set(problem, 'requiredExample', true);
			} else {
				this.$set(problem, 'requiredExample', false);
			}
		},
		evaluationSubmitRequestList(type = 'submit') {
			let evaluationSubmitRequestList = [];

			this.formData.forEach((v) => {
				v.indicatorList.forEach((cv) => {
					if (type === 'submit' && !cv.selectedOptionId) {
						evaluationSubmitRequestList = [];
						throw new RequiredError(`必须选择 ${cv.indicatorName}!`);
					}
					if (this.$route.params.type === 'self' && type === 'submit' && cv.requiredExample && !cv.reason) {
						evaluationSubmitRequestList = [];
						throw new RequiredError(`需为 ${cv.indicatorName} 提供举证说明!`);
					}
					cv.fileList = cv.fileList || [];
					evaluationSubmitRequestList.push({
						indicatorId: cv.indicatorId,
						selectedOptionId: cv.selectedOptionId,
						reason: cv.reason,
						fileList: cv.fileList.map((file) => JSON.stringify([file]))
					});
				});
			});
			return evaluationSubmitRequestList;
		},
		save() {
			const evaluationSubmitRequestList = this.evaluationSubmitRequestList('save');

			this.pageLoaing = true;
			saveEvaluation({
				assessorResultId: this.assessorResultId,
				levelAndCodeId: this.levelAndCodeId,
				reason: this.reason,
				customerComId: this.customerComId,
				staffId: this.staffId,
				accessoryUrl: JSON.stringify(this.accessoryUrl),
				...this.params,
				evaluationSubmitRequestList
			})
				.then(async (res) => {
					this.pageLoaing = false;
					if (res._responseStatusCode === 0) {
						Notify({ type: 'success', message: '保存成功!' });
						const data = await this.getEvaluationForm();

						if (data) {
							this.setEvaluationForm(data);
						}
					}
				})
				.catch(() => {
					this.pageLoaing = false;
				});
		},
		async getEvaluationForm() {
			let params = null;

			let method = null;

			if (sessionStorage.getItem('wapLoginQuery')) {
				params = JSON.parse(sessionStorage.getItem('wapLoginQuery'));
			}
			if (this.$route.params.type === 'self') {
				params = Object.assign(params, JSON.parse(sessionStorage.getItem('selfJudgeWapLoginInfo')));
				method = loginAndStartJudge;
			} else if (this.$route.params.type === 'again') {
				params.customerComId = this.customerComId;
				method = getJugeAgainProblems;
			}
			params = Object.assign(params, this.params);
			return method(params)
				.then((res) => {
					if (res._responseStatusCode === 0) {
						return res;
					}
					return null;
				})
				.catch(() => null);
		},
		setEvaluationForm(problems) {
			this.formData = problems.classList;
			this.assessorResultId = problems.assessorResultId;
			this.levelAndCodeId = problems.levelAndCodeId || null;
			this.saasKey = problems.saasKey;
			this.staffId = problems.staffId;
			this.staffResultId = problems.staffResultId;
			this.reason = problems.reason;
			this.otherEvaluationList = problems.sumOtherEvaluationList;
			this.nativeFileList = JSON.parse(JSON.stringify(problems.accessoryUrl)) || [];
			this.accessoryUrl = problems.accessoryUrl || [];
			this.positionLevelTxt = makePositionLevelTxt(problems.officeInfo);
			this.officeInfo = problems.officeInfo || {};
			this.formData.forEach((v) => {
				v.indicatorList.forEach((cv) => {
					if (!cv.evaluationSaveData || !cv.evaluationSaveData.fileList) {
						this.$set(cv, 'fileList', []);
						this.$set(cv, 'nativeFileList', []);
					} else {
						cv.evaluationSaveData.fileList = cv.evaluationSaveData.fileList || [];
						this.$set(cv, 'fileList', cv.evaluationSaveData.fileList);
						this.$set(
							cv,
							'nativeFileList',
							cv.evaluationSaveData.fileList.map((file) => ({
								name: file.name,
								key: file.key,
								url: file.url,
								size: file.size,
								bucketName: file.bucketName
							}))
						);
					}
					if (!cv.evaluationSaveData || !cv.evaluationSaveData.reason) {
						this.$set(cv, 'reason', '');
					} else {
						this.$set(cv, 'reason', cv.evaluationSaveData.reason);
					}
					if (!cv.evaluationSaveData || !cv.evaluationSaveData.selectedOptionId) {
						this.$set(cv, 'selectedOptionId', null);
					} else {
						this.$set(cv, 'selectedOptionId', cv.evaluationSaveData.selectedOptionId);
					}
				});
			});
		}
	}
};
</script>
<style lang="scss">
.wap-evaluate-p {
	border: none;
	overflow: visible;
	width: 80%;
	.el-message-box__content {
		padding: 0;
	}
	.el-message-box__header {
		background: rgb(128, 128, 128);
		padding: 0;
		.el-message-box__headerbtn {
			border: 1px solid #fff;
			top: -26px;
			right: -21px;
			border-radius: 50%;
			.el-message-box__close {
				color: #fff;
			}
		}
	}
}

.wap-evaluate {
	height: 40vh;
	border-radius: 4px;
	.wap-evaluate_img {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		width: 100%;
		height: 40%;
		background: url('/static/images/evaluate.png') no-repeat center top;
		background-size: 100% auto;
		display: flex;
		align-items: center;
		text-indent: 2em;
		color: #fff;
		font-weight: 700;
		position: relative;
		span {
			border: 1px solid #000;
			text-indent: 0;
			font-weight: normal;
			border-radius: 50%;
			text-align: center;
			width: 20px;
			height: 20px;
			position: absolute;
			right: -22px;
			top: -22px;
			cursor: pointer;
		}
	}

	.wap-evaluate_text {
		background: #fff;
		height: 60%;
		display: flex;
		// align-items:
		flex-wrap: wrap;
		justify-content: space-around;
		h3,
		p {
			width: 100%;
			text-align: center;
			color: rgb(144, 49, 238);
			font-size: 16px;
		}

		h3 {
			padding: 14px 0;
			font-size: 20px;
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		p {
			font-weight: 700;
			em {
				font-weight: 700;
			}
			span {
				color: #f00;
				padding-left: 6px;
			}
		}
	}
}
.evaluation-table {
	border-collapse: collapse;
	border-spacing: 0;
	border-color: #c5cdda;
	font-size: 14px;
	.evaluation-table__header {
		background: #f9faff;
	}
	tr {
		border: 1px solid #c5cdda;
	}
	td:first-child {
		width: 75px;
	}
	td,
	th {
		border-right: 1px solid #c5cdda;
		border-color: #c5cdda;
		border-collapse: collapse;
		vertical-align: middle;
		height: 45px;
		line-height: 1.5em;
		text-align: center;
		color: #777e8c;
	}
}
.sheet-title {
	font-size: 14px;
	color: #606266;
	font-weight: bold;
	margin-top: 4px;
	.el-col {
		text-align: center;
	}
}
.hidden-popper {
	display: none !important;
}
.popup-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.position-level-item__wap {
	font-size: 12px;
	min-height: 36px;
	padding: 12px 0;
	box-sizing: border-box;
	border-bottom: 1px solid #e1e1e1;
	color: #a1a1a1;
	user-select: none;
	.el-col {
		text-align: center;
	}
	&.active {
		background-color: rgba(#0000, 0.03);
		cursor: pointer;
		color: #409eff;
	}
}
.reply-report {
	.rating-item {
		margin-top: 24px;
	}
}
.rating-page__wap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background-color: #f1f3f4;
	.select-item {
		width: 100%;
	}
	.disable-input__suffix {
		.el-input__suffix {
			display: none;
		}
	}
	.title-icon {
		font-size: 18px;
		display: flex;
		align-items: center;
		span {
			display: inline-block;
			height: 20px;
			vertical-align: text-bottom;
			line-height: 20px;
		}
		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			margin-right: 5px;
		}
	}
	.section {
		padding: 10px 15px;
		margin-bottom: 15px;
		background-color: #fff;
	}
	.fj-icon {
		width: calc(100% - 75px);
	}
	.link-text {
		// white-space: nowrap;
		cursor: pointer;
		text-overflow: ellipsis;
		overflow: hidden;
		&:hover {
			color: #66b1ff;
		}
	}
	.rating-page-left {
		width: 100vw;
		// border-right: 1px dotted #a1a1a1;
	}
	.rating-item {
		margin-bottom: 30px;
		h2.title {
			line-height: 3em;
			font-weight: bold;
		}
		h3.title {
			line-height: 2.5em;
		}
		.radio-group {
			.el-radio {
				margin-right: 0;
			}
			.el-radio__input {
				padding-top: 4px;
			}
			margin-bottom: 16px;
			.el-radio__label {
				letter-spacing: 1px;
				max-width: 100%;
				line-height: 1.5em;
			}
			.radio-item {
				display: flex;
				white-space: pre-wrap;
				word-spacing: 4px;
				padding: 4px 0;
			}
		}
		.for-exemple {
			margin: 10px 0;
		}
		.select-position {
			width: 100%;
			margin-bottom: 16px;
		}
	}
	.rating-page-left,
	.rating-page-right {
		// flex-shrink: 1;
		max-width: 1024px;
		box-sizing: border-box;
		flex-grow: 1;
	}
}
</style>
